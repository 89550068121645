<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-17 13:46:56
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-12-11 09:36:58
-->
<template>
  <el-table highlight-current-row  :data="planList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="tardName" label="购液企业"></el-table-column>
    <el-table-column prop="count" label="未审核数量"></el-table-column>
    <el-table-column label="操作" width="200px" fixed="right">
      <template slot-scope="scope">
        <el-button type="text" size="" @click="detailsPage(scope.row)">计划审核</el-button>
      </template>
    </el-table-column>
  </el-table>
  
</template>

<script>
// import PlanEdit from './PlanEdit.vue';

  export default {
    name: "PlanAuditTable",
    props: {
      planList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
        // PlanEdit
      
    },
    methods: {
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      },
      detailsPage(scoped){
        this.$router.replace({path:'/pages/biz/gas_factory/plan/check/details',query: {id:scoped.tardId,name: scoped.tardName,balance: scoped.balance}})
      },
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
