<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>计划管理</template>
        <template v-slot:secondMenu>计划审核</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        计划审核
      </div>
      <div class="radioBox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="购液企业">
            <el-input autosize v-model="formInline.name" placeholder="请搜索购液企业名称" @input="onSubmit"></el-input>
          </el-form-item>
      </el-form>
      </div>
      <!-- table表格区域 -->
      <plan-audit-table :plan-list='goodsList'/>
      <div style="float: right;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import BreadcrumbNav from "../common/BreadcrumbNav";
  import { getPlanAuditListRequest } from "network/plan";
  import PlanAuditTable from './PlanAuditComponents/PlanAuditTable';


  export default {
    name: "List",
    components: {
      BreadcrumbNav,
      PlanAuditTable
    },
    data() {
      return {
        queryInfo: {
          // query: "",
          pageNum: 1,
          pageSize: 10,
          tardId:localStorage.getItem('enterpriseId')
        },
        formInline: {
          name: ''
        },
        goodsList: [],
        total: 0,
      }
    },
    created() {
      this.getGoodsList();
    },
    methods: {
      onSubmit(){
        this.getGoodsList()
      },
      getGoodsList() {
        getPlanAuditListRequest({
          ...this.queryInfo,
          enterpriseName: this.formInline.name
          // tardId:localStorage.getItem('enterpriseId')
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取计划数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage;
        this.getGoodsList();
      },

    }
  }
</script>

<style scoped>

.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
}
.buttonBox{
  display: flex;
}
.el-button{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}


</style>
